import React, { useState, useEffect } from "react";
import Footer from "../Component/Footer";
import Navbar from "../Component/Navbar";
import Topbar from "../Component/Topbar";

const PageNotFound = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time or fetch data if needed
    const timer = setTimeout(() => {
      setLoading(false); // After 1 second, set loading to false
    }, 1000);
    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);

  return (
    <div>
      {/* Spinner */}
      {loading && (
        <div
          id="spinner"
          className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
        >
          <div
            className="spinner-border position-relative text-primary"
            style={{ width: "6rem", height: "6rem" }}
            role="status"
          ></div>
          <img
            className="position-absolute top-50 start-50 translate-middle"
            src="img/icons/icon-1.png"
            alt="Icon"
          />
        </div>
      )}

      {/* Content */}
      {!loading && (
        <>
          {/* Topbar Start */}
          <Topbar />
          {/* Topbar End */}

          {/* Navbar Start */}
          <Navbar />
          {/* Navbar End */}

          <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
            <h1 className="display-1 text-white animated slideInDown">404 Error</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb text-uppercase mb-0">
                    <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
                    <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                    <li className="breadcrumb-item text-primary active" aria-current="page">404 Error</li>
                </ol>
            </nav>
        </div>
    </div>

          <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container text-center">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
                  <h1 className="display-1">404</h1>
                  <h1 className="mb-4">Page Not Found</h1>
                  <p className="mb-4">
                    We’re sorry, the page you have looked for does not exist in
                    our website! Maybe go to our home page or try to use a
                    search?
                  </p>
                  <a className="btn btn-primary py-3 px-5" href="">
                    Go Back To Home
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Footer Start */}
          <Footer />
          {/* Footer End */}

          {/* Back to Top */}
          <a
            href="#"
            className="btn btn-lg btn-primary btn-lg-square back-to-top"
          >
            <i className="bi bi-arrow-up"></i>
          </a>
        </>
      )}
    </div>
  );
};

export default PageNotFound;
