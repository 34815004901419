import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./Component/Home/App";
import AboutLink from "./Component/About/AboutLink";
import ServicesPage from "./Component/Services/ServicesPage";
import ProjectPage from "./Component/Project/ProjectPage";
import TemMemberPage from "./Component/TeamMember/TemMemberPage";
import AppointmentPage from "./Component/Appointment/AppointmentPage";
import PageNotFound from "./Component/PageNotFound";
import ContactPage from "./Component/Contact/ContactPage";
import OurFeaturesPage from "./Component/OurFeature/OurFeaturesPage";
import TestimonialPage from "./Component/TestimonialFoP/TestimonialPage";

const routing = (
  <Router>
    <Routes>
      <Route path="/" element={<App/>} />
      <Route path="/about" element={<AboutLink/>} />
      <Route path="/services" element={<ServicesPage/>} />
      <Route path="/Features" element={<OurFeaturesPage/>} />
      <Route path="/Project" element={<ProjectPage/>} />
      <Route path="/Teammember" element={<TemMemberPage/>} />
      <Route path="/Appointment" element={<AppointmentPage/>} />
      <Route path="/Testimonial" element={<TestimonialPage/>} />
      <Route path="/404Page" element={<PageNotFound/>} />
      <Route path="/Contact" element={<ContactPage/>} />
    </Routes>
  </Router>
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  routing
);

reportWebVitals();
