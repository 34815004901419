import React, { useState, useEffect } from "react";
import AboutStart from "./AboutStart";
import AboutHeader from "./AboutHeader";
import Features from "./Features";
import Topbar from "../Topbar";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TeamAbout from "./TeamAbout";

const AboutLink = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time or data fetching
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after 1 second
    }, 1000);
    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);

  return (
    <div>
      {/* Spinner */}
      {loading && (
        <div
          id="spinner"
          className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
        >
          <div
            className="spinner-border position-relative text-primary"
            style={{ width: "6rem", height: "6rem" }}
            role="status"
          ></div>
          <img
            className="position-absolute top-50 start-50 translate-middle"
            src="img/icons/icon-1.png"
            alt="Icon"
          />
        </div>
      )}

      {/* Content */}
      {!loading && (
        <>
          {/* Topbar Start */}
          <Topbar/>
          {/* Topbar End */}

          {/* Navbar Start */}
          <Navbar/>
          {/* Navbar End */}

          {/* Page Header Start */}
          <AboutHeader />
          {/* Page Header End */}

          {/* About Start */}
          <AboutStart />
          {/* About End */}

          {/* Feature Start */}
          <Features/>
          {/* Feature End */}

          {/* Team Start */}
          <TeamAbout/>
          {/* Team End */}

          {/* Footer Start */}
          <Footer/>
          {/* Footer End */}

          {/* Back to Top */}
          <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
            <i className="bi bi-arrow-up"></i>
          </a>
        </>
      )}
    </div>
  );
};

export default AboutLink;






// import Navbar from "../Component/Navbar";
// import Footer from "../Component/Footer";
// import Topbar from "../Component/Topbar";
// import Features from "./Features";
// import TeamAbout from "./TeamAbout";
// import AboutStart from "./AboutStart";
// import AboutHeader from "./AboutHeader";

// const AboutLink = () => {
//   return (
//     <div>
//       {/* <!-- Spinner Start --> */}
//       <div
//         id="spinner"
//         className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
//       >
//         <div
//           className="spinner-border position-relative text-primary"
//           style={{ width: "6rem", height: "6rem" }}
//           role="status"
//         ></div>
//         <img
//           className="position-absolute top-50 start-50 translate-middle"
//           src="img/icons/icon-1.png"
//           alt="Icon"
//         />
//       </div>
//       {/* <!-- Spinner End --> */}

//       {/*<!-- Topbar Start --> */}
//       <Topbar />
//       {/* <!-- Topbar End -->
    
    
//         <!-- Navbar Start --> */}
//       <Navbar />
//       {/* <!-- Navbar End --> */}

//       {/* <!-- Page Header Start --> */}
//       <AboutHeader />
//       {/* <!-- Page Header End --> */}

//       {/* <!-- About Start --> */}
//       <AboutStart />
//       {/* <!-- About End --> */}

//       {/* <!-- Feature Start --> */}
//       <Features />
//       {/* <!-- Feature End --> */}

//       {/* <!-- Team Start --> */}
//       <TeamAbout />
//       {/* <!-- Team End --> */}

//       {/* <!-- Footer Start --> */}
//       <Footer />
//       {/* <!-- Footer End --> */}

//       {/* <!-- Back to Top --> */}
//       <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
//         <i className="bi bi-arrow-up"></i>
//       </a>
//     </div>
//   );
// };

// export default AboutLink;
