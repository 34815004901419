import { Link, NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <nav
      className="navbar navbar-expand-lg bg-white navbar-light sticky-top py-lg-0 px-lg-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <Link to="/" className="navbar-brand ms-4 ms-lg-0">
        <h1 className="text-primary m-0">
          <img className="me-3" src="img/icons/icon-1.png" alt="Icon" />
          Arkitektur
        </h1>
      </Link>
      <button
        type="button"
        className="navbar-toggler me-4"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto p-4 p-lg-0">
          <NavLink
            to="/"
            className="nav-item nav-link active"
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            className="nav-item nav-link"
          >
            About
          </NavLink>
          <NavLink
            to="/services"
            className="nav-item nav-link"
          >
            Services
          </NavLink>
          <div className="nav-item dropdown">
            <NavLink
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              Pages
            </NavLink>
            <div className="dropdown-menu border-0 m-0">
              <NavLink to="/Features" className="dropdown-item">
                Our Features
              </NavLink>
              <NavLink to="/Project" className="dropdown-item">
                Our Projects
              </NavLink>
              <NavLink to="/Teammember" className="dropdown-item">
                Team Members
              </NavLink>
              <NavLink to="/Appointment" className="dropdown-item">
                Appointment
              </NavLink>
              <NavLink to="/Testimonial" className="dropdown-item">
                Testimonial
              </NavLink>
              <NavLink to="/404Page" className="dropdown-item">
                404 Page
              </NavLink>
            </div>
          </div>
          <NavLink
            to="/Contact"
            className="nav-item nav-link"
          >
            Contact
          </NavLink>
        </div>
        {/* <a
          href="https://htmlcodex.com/architecture-html-template-pro"
          className="btn btn-primary py-2 px-4 d-none d-lg-block"
        >
          Pro Version
        </a> */}
      </div>
    </nav>
  );
};

export default Navbar;
