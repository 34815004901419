import React, { useState, useEffect } from "react";
import Topbar from "../Topbar";
import Navbar from "../Navbar";
import Carousel from "../Carousel";
import Facts from "../Services/Facts";
import OurServices from "../Services/OurServices";
import TeamAbout from "../About/TeamAbout";
import AppointmentContent from "../Appointment/AppointmentContent";
import TestimonialSlider from "../Services/TestimonialSlider";
import Footer from "../Footer";
import AboutStart from "../About/AboutStart";
import PContent from "../Project/PContent";
import Features from "../About/Features";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a spinner timeout for 1 second
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, []);

  if (loading) {
    return (
      <div
        id="spinner"
        className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          className="spinner-border position-relative text-primary"
          style={{ width: "6rem", height: "6rem" }}
          role="status"
        ></div>
        <img
          className="position-absolute top-50 start-50 translate-middle"
          src="img/icons/icon-1.png"
          alt="Icon"
        />
      </div>
    );
  }

  return (
    <div>
      {/* Topbar */}
      <Topbar/>

      {/* Navbar */}
      <Navbar/>

      {/* Carousel */}
      <Carousel/>

      {/* Facts Section */}
      <Facts/>

      {/* About Section */}
      <AboutStart/>

      {/* Services Section */}
      <OurServices/>

      {/* Features Section */}
      <Features/>

      {/* Projects Section */}
      <PContent/>

      {/* Team Section */}
      <TeamAbout/>

      {/* Appointment Section */}
      <AppointmentContent/>

      {/* Testimonial Section */}
      <TestimonialSlider/>

      {/* Footer */}
      <Footer/>

      {/* Back to Top */}
      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
        <i className="bi bi-arrow-up"></i>
      </a>
    </div>
  );
};

export default App;
