import React, { useState, useEffect } from "react";
import Topbar from "../Topbar";
import Navbar from "../Navbar";
import ProjectHeader from "./ProjectHeader";
import PContent from "./PContent";
import Footer from "../Footer";

const ProjectPage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time or fetch data if needed
    const timer = setTimeout(() => {
      setLoading(false); // After 1 second, set loading to false
    }, 1000);
    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);

  return (
    <div>
      {/* Spinner */}
      {loading && (
        <div
          id="spinner"
          className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
        >
          <div
            className="spinner-border position-relative text-primary"
            style={{ width: "6rem", height: "6rem" }}
            role="status"
          ></div>
          <img
            className="position-absolute top-50 start-50 translate-middle"
            src="img/icons/icon-1.png"
            alt="Icon"
          />
        </div>
      )}

      {/* Content */}
      {!loading && (
        <>
          {/* Topbar Start */}
          <Topbar/>
          {/* Topbar End */}

          {/* Navbar Start */}
          <Navbar/>
          {/* Navbar End */}

          {/* Page Header Start */}
          <ProjectHeader/>
          {/* Page Header End */}

          <PContent/>

          {/* Footer Start */}
          <Footer/>
          {/* Footer End */}

          {/* Back to Top */}
          <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
            <i className="bi bi-arrow-up"></i>
          </a>
        </>
      )}
    </div>
  );
};

export default ProjectPage;





// import React, { useState, useEffect } from "react";
// import Footer from "../Component/Footer";
// import Navbar from "../Component/Navbar";
// import Topbar from "../Component/Topbar";
// import Facts from "./Facts";
// import OurServices from "./OurServices";
// import ServiceHeader from "./ServiceHeader";
// import TestimonialSlider from "./TestimonialSlider";

// const ServicesPage = () => {
//   const [loading, setLoading] = useState(true);

//   // Handle route change and loading state
//   useEffect(() => {
//     setLoading(true); // Set loading to true on route change
//     const timer = setTimeout(() => setLoading(false), 1000); // Simulate a loading time
//     return () => clearTimeout(timer); // Clear timeout on cleanup
//   }, [location]);
//   return (
//     <div>
//       {/* <!-- Spinner Start --> */}
//       <div
//         id="spinner"
//         className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
//       >
//         <div
//           className="spinner-border position-relative text-primary"
//           style={{ width: "6rem", height: "6rem" }}
//           role="status"
//         ></div>
//         <img
//           className="position-absolute top-50 start-50 translate-middle"
//           src="img/icons/icon-1.png"
//           alt="Icon"
//         />
//       </div>
//       {/* <!-- Spinner End --> */}

//       {/*<!-- Topbar Start --> */}
//       <Topbar />
//       {/* <!-- Topbar End -->
    
    
//         <!-- Navbar Start --> */}
//       <Navbar />
//       {/* <!-- Navbar End --> */}

//       {/* <!-- Page Header Start -->   */}
//       <ServiceHeader />
//       {/* <!-- Page Header End --> */}

//       <Facts />
//       <OurServices />
//       <TestimonialSlider />

//       {/* <!-- Footer Start --> */}
//       <Footer />
//       {/* <!-- Footer End --> */}

//       {/* <!-- Back to Top --> */}
//       <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
//         <i className="bi bi-arrow-up"></i>
//       </a>
//     </div>
//   );
// };

// export default ServicesPage;
