const OurServices = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: "600px" }}
        >
          <h4 className="section-title">Our Services</h4>
          <h1 className="display-5 mb-4">
            We Focused On Modern Architecture And Interior Design
          </h1>
        </div>
        <div className="row g-4">
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="service-item d-flex position-relative text-center h-100">
              <img className="bg-img" src="img/service-1.jpg" alt="" />
              <div className="service-text p-5">
                <img className="mb-4" src="img/icons/icon-5.png" alt="Icon" />
                <h3 className="mb-3">Architecture</h3>
                <p className="mb-4">
                  Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                  lorem sed diam stet diam sed stet.
                </p>
                <a className="btn" href="">
                  <i className="fa fa-plus text-primary me-3"></i>Read More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item d-flex position-relative text-center h-100">
              <img className="bg-img" src="img/service-2.jpg" alt="" />
              <div className="service-text p-5">
                <img className="mb-4" src="img/icons/icon-6.png" alt="Icon" />
                <h3 className="mb-3">3D Animation</h3>
                <p className="mb-4">
                  Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                  lorem sed diam stet diam sed stet.
                </p>
                <a className="btn" href="">
                  <i className="fa fa-plus text-primary me-3"></i>Read More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="service-item d-flex position-relative text-center h-100">
              <img className="bg-img" src="img/service-3.jpg" alt="" />
              <div className="service-text p-5">
                <img className="mb-4" src="img/icons/icon-7.png" alt="Icon" />
                <h3 className="mb-3">House Planning</h3>
                <p className="mb-4">
                  Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                  lorem sed diam stet diam sed stet.
                </p>
                <a className="btn" href="">
                  <i className="fa fa-plus text-primary me-3"></i>Read More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="service-item d-flex position-relative text-center h-100">
              <img className="bg-img" src="img/service-4.jpg" alt="" />
              <div className="service-text p-5">
                <img className="mb-4" src="img/icons/icon-8.png" alt="Icon" />
                <h3 className="mb-3">Interior Design</h3>
                <p className="mb-4">
                  Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                  lorem sed diam stet diam sed stet.
                </p>
                <a className="btn" href="">
                  <i className="fa fa-plus text-primary me-3"></i>Read More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item d-flex position-relative text-center h-100">
              <img className="bg-img" src="img/service-5.jpg" alt="" />
              <div className="service-text p-5">
                <img className="mb-4" src="img/icons/icon-9.png" alt="Icon" />
                <h3 className="mb-3">Renovation</h3>
                <p className="mb-4">
                  Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                  lorem sed diam stet diam sed stet.
                </p>
                <a className="btn" href="">
                  <i className="fa fa-plus text-primary me-3"></i>Read More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="service-item d-flex position-relative text-center h-100">
              <img className="bg-img" src="img/service-6.jpg" alt="" />
              <div className="service-text p-5">
                <img className="mb-4" src="img/icons/icon-10.png" alt="Icon" />
                <h3 className="mb-3">Construction</h3>
                <p className="mb-4">
                  Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                  lorem sed diam stet diam sed stet.
                </p>
                <a className="btn" href="">
                  <i className="fa fa-plus text-primary me-3"></i>Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;