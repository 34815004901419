import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "animate.css/animate.min.css"; // To support animated CSS classes
// import "./Carousel.css"; // Assuming you moved your custom CSS here

const Carousel = () => {
  const options = {
    autoplay: true,
    smartSpeed: 1500,
    loop: true,
    nav: false,
    dots: true,
    items: 1,
    dotsData: true, // This enables custom thumbnails for dots
  };

  return (
    <div className="container-fluid p-0 pb-5 wow fadeIn" data-wow-delay="0.1s">
      <OwlCarousel className="owl-theme header-carousel position-relative" {...options}>
        {/* Slide 1 */}
        <div className="owl-carousel-item position-relative" data-dot="<img src='img/carousel-1.jpg'>">
          <img className="img-fluid" src="img/carousel-1.jpg" alt="Slide 1" />
          <div className="owl-carousel-inner">
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-10 col-lg-8">
                  <h1 className="display-1 text-white animated slideInDown">
                    Best Architecture And Interior Design Services
                  </h1>
                  <p className="fs-5 fw-medium text-white mb-4 pb-3">
                    Vero elitr justo clita lorem. Ipsum dolor at sed stet sit diam no. Kasd rebum ipsum et diam justo clita
                    et kasd rebum sea elitr.
                  </p>
                  <a href="#" className="btn btn-primary py-3 px-5 animated slideInLeft">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Slide 2 */}
        <div className="owl-carousel-item position-relative" data-dot="<img src='img/carousel-2.jpg'>">
          <img className="img-fluid" src="img/carousel-2.jpg" alt="Slide 2" />
          <div className="owl-carousel-inner">
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-10 col-lg-8">
                  <h1 className="display-1 text-white animated slideInDown">
                    Best Architecture And Interior Design Services
                  </h1>
                  <p className="fs-5 fw-medium text-white mb-4 pb-3">
                    Vero elitr justo clita lorem. Ipsum dolor at sed stet sit diam no. Kasd rebum ipsum et diam justo clita
                    et kasd rebum sea elitr.
                  </p>
                  <a href="#" className="btn btn-primary py-3 px-5 animated slideInLeft">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Slide 3 */}
        <div className="owl-carousel-item position-relative" data-dot="<img src='img/carousel-3.jpg'>">
          <img className="img-fluid" src="img/carousel-3.jpg" alt="Slide 3" />
          <div className="owl-carousel-inner">
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-10 col-lg-8">
                  <h1 className="display-1 text-white animated slideInDown">
                    Best Architecture And Interior Design Services
                  </h1>
                  <p className="fs-5 fw-medium text-white mb-4 pb-3">
                    Vero elitr justo clita lorem. Ipsum dolor at sed stet sit diam no. Kasd rebum ipsum et diam justo clita
                    et kasd rebum sea elitr.
                  </p>
                  <a href="#" className="btn btn-primary py-3 px-5 animated slideInLeft">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </div>
  );
};

export default Carousel;