import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const testimonials = [
  {
    text: "Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed. Magna ut diam sit et amet stet eos sed clita erat magna elitr erat sit sit erat at rebum justo sea clita.",
    name: "Client Name 1",
    profession: "Profession 1",
    image: "./../testimonial-1.jpg",
  },
  {
    text: "Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed. Magna ut diam sit et amet stet eos sed clita erat magna elitr erat sit sit erat at rebum justo sea clita.",
    name: "Client Name 2",
    profession: "Profession 2",
    image: "../img/testimonial-2.jpg",
  },
  {
    text: "Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed. Magna ut diam sit amet stet eos sed clita erat magna elitr erat sit sit erat at rebum justo sea clita.",
    name: "Client Name 3",
    profession: "Profession 3",
    image: "../img/testimonial-3.jpg",
  },
];

const TestimonialSlider = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: "600px" }}
        >
          <h4 className="section-title">Testimonial</h4>
          <h1 className="display-5 mb-4">
            Thousands Of Customers Who Trust Us And Our Services
          </h1>
        </div>
        <OwlCarousel
          className="owl-carousel testimonial-carousel wow fadeInUp"
          autoplay
          smartSpeed={1000}
          loop
          nav={false}
          dots
          items={1}
          dotsData={true}
        >
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="testimonial-item text-center"
              data-dot={`<img class='img-fluid' src='${testimonial.image}' alt=''></img>`}
            >
              <p className="fs-5">{testimonial.text}</p>
              <h3>{testimonial.name}</h3>
              <span className="text-primary">{testimonial.profession}</span>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default TestimonialSlider;

// const TestimonialSlider = () => {
//   return (
//     <div className="container-xxl py-5">
//       <div className="container">
//         <div
//           className="text-center mx-auto mb-5 wow fadeInUp"
//           data-wow-delay="0.1s"
//           style={{ maxWidth: "600px" }}
//         >
//           <h4 className="section-title">Testimonial</h4>
//           <h1 className="display-5 mb-4">
//             Thousands Of Customers Who Trust Us And Our Services
//           </h1>
//         </div>
//         <div
//           className="owl-carousel testimonial-carousel wow fadeInUp"
//           data-wow-delay="0.1s"
//         >
//           <div
//             className="testimonial-item text-center"
//             data-dot="<img className='img-fluid' src='img/testimonial-1.jpg' alt=''>"
//           >
//             <p className="fs-5">
//               Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo
//               duo labore sed sed. Magna ut diam sit et amet stet eos sed clita
//               erat magna elitr erat sit sit erat at rebum justo sea clita.
//             </p>
//             <h3>Client Name</h3>
//             <span className="text-primary">Profession</span>
//           </div>
//           <div
//             className="testimonial-item text-center"
//             data-dot="<img className='img-fluid' src='img/testimonial-2.jpg' alt=''>"
//           >
//             <p className="fs-5">
//               Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo
//               duo labore sed sed. Magna ut diam sit et amet stet eos sed clita
//               erat magna elitr erat sit sit erat at rebum justo sea clita.
//             </p>
//             <h3>Client Name</h3>
//             <span className="text-primary">Profession</span>
//           </div>
//           <div
//             className="testimonial-item text-center"
//             data-dot="<img className='img-fluid' src='img/testimonial-3.jpg' alt=''>"
//           >
//             <p className="fs-5">
//               Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo
//               duo labore sed sed. Magna ut diam sit et amet stet eos sed clita
//               erat magna elitr erat sit sit erat at rebum justo sea clita.
//             </p>
//             <h3>Client Name</h3>
//             <span className="text-primary">Profession</span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TestimonialSlider;
